import React, { useState, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button, Form } from "reactstrap";
import CustomModal from "components/Modal/CustomModal";
import CustomSelect from "components/Inputs/CustomSelect";
import CustomNumberInput from "components/Inputs/CustomNumberInput";
import CustomDateInput from "components/Inputs/CustomDateInput";
import { currentDate } from "utils/currentDate";
import { useDispatch, useSelector } from "react-redux";
import { createControlKitStockHistory } from "store/actions";
import CustomTextArea from "components/Inputs/CustomTextArea";
import { controlKitStockHistoryResults } from "variables/options";

const ControlKitStockHistoryCreate = ({ isOpen, toggle, type }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({
    defaultValues: {
      control_kit_qty: 0,
      chip_qty: 0,
      trueprep_auto_mtb_sample_pretreatment_qty: 0,
      trueprep_auto_v2_universal_cartridge_qty: 0,
    },
  });

  const [loading, setLoading] = useState(false);
  const [selectedResult, setSelectedResult] = useState(null);

  const dispatch = useDispatch();
  const { status } = useSelector((state) => state);

  const onSubmit = async (data) => {
    setLoading(true);
    console.log(data.trueprep_auto_v2_universal_cartridge_qty);
    await dispatch(
      createControlKitStockHistory({
        control_kit_qty: data.control_kit_qty,
        chip_qty: data.chip_qty,
        trueprep_auto_mtb_sample_pretreatment_qty: data.trueprep_auto_mtb_sample_pretreatment_qty,
        trueprep_auto_v2_universal_cartridge_qty:
          data.trueprep_auto_v2_universal_cartridge_qty,
        type: type,
        date: data.date,
        result: selectedResult?.value,
        // batch_no: data.batch_no,
        // expired_date: data.expired_date,
        remark: data.remark,
      })
    );
    setLoading(false);
  };

  const formReset = useCallback(() => {
    setSelectedResult(null);
    reset();
  }, [reset, setSelectedResult]);

  useEffect(() => {
    if (status.success) {
      formReset();
      toggle()
    }

    return () => formReset();
  }, [status.success, formReset, toggle]);

  return (
    <CustomModal
      isOpen={isOpen}
      title={`Create Control Kit ${type}`}
      onClick={() => {
        toggle(false);
        formReset();
      }}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <CustomNumberInput
            id="control_kit_qty"
            label="Control Kit Qty"
            register={{
              ...register("control_kit_qty", {
                required: "Control Kit Qty is required!",
              }),
            }}
            placeholder="Enter Control Kit qty"
            errors={errors}
            max={1}
          />
          <CustomNumberInput
            id="chip_qty"
            label="Chip Qty"
            register={{
              ...register("chip_qty", {
                required: "Chip Qty is required!",
              }),
            }}
            placeholder="Enter Chip qty"
            errors={errors}
            max={1}
          />
          <CustomNumberInput
            label="Trueprep Auto MTB Sample Pretreatment Qty"
            id="trueprep_auto_mtb_sample_pretreatment_qty"
            register={{
              ...register("trueprep_auto_mtb_sample_pretreatment_qty", {
                required:
                  "trueprep_auto_mtb_sample_pretreatment qty is required!",
              }),
            }}
            placeholder="Enter qty"
            errors={errors}
          />
          <CustomNumberInput
            label="Trueprep Auto v2 Universal Catridge Qty"
            id="trueprep_auto_v2_universal_cartridge_qty"
            register={{
              ...register("trueprep_auto_v2_universal_cartridge_qty", {
                required:
                  "trueprep_auto_v2_universal_cartridge qty is required!",
              }),
            }}
            placeholder="Enter qty"
            errors={errors}
          />
          <CustomDateInput
            id="date"
            label="Date"
            register={{
              ...register("date", {
                required: "Date is required!",
              }),
            }}
            placeholder="Select Date"
            errors={errors}
            isRequired={true}
            max={currentDate()}
          />
          {/* {type === RECEIVED && (
            <>
              <CustomInput
                id="batch_no"
                label="Batch No"
                register={{
                  ...register("batch_no", {
                    required: "Batch No is required!",
                  }),
                }}
                placeholder="Enter Batch No"
                errors={errors}
                isRequired={true}
              />
              <CustomDateInput
                id="expired_date"
                label="Expired Date"
                register={{
                  ...register("expired_date", {
                    required: "Expired Date is required!",
                  }),
                }}
                placeholder="Select Expired Date"
                errors={errors}
                isRequired={true}
              />
            </>
          )} */}
          <CustomSelect
            id="result"
            label="Select Result"
            rules={{ required: "Result is required!" }}
            control={control}
            options={controlKitStockHistoryResults}
            value={selectedResult}
            setData={setSelectedResult}
            placeholder="Select Result"
          />
          <CustomTextArea
            id="remark"
            label="Remark"
            register={{
              ...register("remark"),
            }}
            placeholder="Enter Remark"
            errors={errors}
            isRequired={false}
          />
        </div>
        <div className="modal-footer">
          <Button color="primary" size="sm" type="submit" disabled={loading}>
            {loading ? "Loading" : "Save"}
          </Button>
        </div>
      </Form>
    </CustomModal>
  );
};

export default ControlKitStockHistoryCreate;
